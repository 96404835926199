import { FormikInput } from '@finn/auto-ui/components/B2BContactForm/Form';
import FormikCheckbox from '@finn/auto-ui/components/FormElements/Checkbox/FormikCheckbox';
import { LegalText } from '@finn/auto-ui/modules/legal/constants';
import { getLegalAllText } from '@finn/auto-ui/modules/legal/utils';
import { Button } from '@finn/design-system';
import {
  B2BFormTypeCosmicData,
  B2BLeadGenFormTypeCosmicData,
  ContactUsFormTypeCosmicData,
  UIBaseCosmicObject,
} from '@finn/ui-cosmic';
import { parseToHtml } from '@finn/ui-utils';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Box, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { FormikProps } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import SelectDropdown from '~/components/FormElements/SelectDropdown';
import TextArea from '~/components/FormElements/TextArea';
import { getInputPlaceholder } from '~/utils/cosmic';

import { ContactValues as B2BContactValues } from './B2BContactForm/config';
import { ContactValues as B2BLeadGenFormValues } from './B2BLeadGenForm/config';
import { ContactValues as ContactUsValues } from './ContactUsForm/config';
import { FaqRecommender } from './FaqRecommender/FaqRecommender';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      gridGap: theme.spacing(3),
      gridTemplateColumns: '1fr 1fr',
    },
  },
  message: {
    [theme.breakpoints.up('sm')]: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
  },
  supportTopic: {
    [theme.breakpoints.up('sm')]: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
  },
  button: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
  },
  fullWidth: {
    [theme.breakpoints.up('sm')]: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
  },
  gdpr_container: {
    marginTop: -theme.spacing(1),
  },
}));

type B2BType = UIBaseCosmicObject<B2BFormTypeCosmicData>;
type ContactUsType = UIBaseCosmicObject<ContactUsFormTypeCosmicData>;
type PromoType = UIBaseCosmicObject<B2BLeadGenFormTypeCosmicData>;

interface IProps {
  formik:
    | FormikProps<B2BContactValues>
    | FormikProps<ContactUsValues>
    | FormikProps<B2BLeadGenFormValues>;
  loading: boolean;
  cosmicData: B2BType | ContactUsType | PromoType;
  isB2B?: boolean;
}
const Form: React.FunctionComponent<IProps> = ({
  formik,
  loading,
  cosmicData,
  isB2B,
}) => {
  const classes = useStyles();
  const [showGdprDetails, setShowGdprDetails] = useState(false);
  const [animationParent] = useAutoAnimate();
  const messageFieldValue = formik.getFieldMeta<string>('message').value;
  const i18n = useIntl();
  const gdprCheckbox = getLegalAllText(
    i18n.locale,
    LegalText.GDPR_GENERIC_CONTACT_FORM_CHECKBOX
  );
  const gdprDetails = getLegalAllText(
    i18n.locale,
    LegalText.GDPR_GENERIC_CONTACT_FORM_DETAILS
  );
  const newsletterCheckboxText = getLegalAllText(
    i18n.locale,
    LegalText.NEWSLETTER_CHECKBOX_TEXT
  );

  const createOptions = (options: string[]) =>
    options.map((topic: string) => ({ value: topic, label: topic }));

  const handleGDPRToggle = (e) => {
    e.preventDefault();
    setShowGdprDetails(!showGdprDetails);
  };

  return (
    <form>
      <Box className={classes.wrapper}>
        {'message' in cosmicData.metadata.input_fields && !isB2B && (
          <div className={classes.message} ref={animationParent}>
            <TextArea
              name="message"
              placeholder={getInputPlaceholder(cosmicData, 'message')}
            />
            <FaqRecommender messageFieldValue={messageFieldValue} />
          </div>
        )}
        {'support_topic' in cosmicData.metadata.input_fields && (
          <Box className={classes.supportTopic}>
            <SelectDropdown
              options={createOptions(
                (cosmicData as ContactUsType).metadata.dropdown_options
                  .support_topic
              )}
              placeholder={getInputPlaceholder(cosmicData, 'support_topic')}
              name="topic"
            />
          </Box>
        )}
        <Box>
          <SelectDropdown
            name="salutation"
            placeholder={getInputPlaceholder(cosmicData, 'salutation')}
            options={
              isB2B
                ? (cosmicData as B2BType).metadata.salutation_options
                : (cosmicData as ContactUsType).metadata.dropdown_options
                    .salutation
            }
          />
        </Box>

        {'company' in cosmicData.metadata.input_fields && (
          <Box>
            <FormikInput
              name="company"
              label={getInputPlaceholder(cosmicData, 'company')}
            />
          </Box>
        )}
        <Box>
          <FormikInput
            name={isB2B ? 'firstName' : 'firstname'}
            label={getInputPlaceholder(cosmicData, 'first_name')}
          />
        </Box>
        <Box>
          <FormikInput
            name={isB2B ? 'lastName' : 'lastname'}
            label={getInputPlaceholder(cosmicData, 'last_name')}
          />
        </Box>
        <Box>
          <FormikInput
            name="email"
            type="email"
            label={getInputPlaceholder(cosmicData, 'email_address')}
          />
        </Box>
        <Box>
          <FormikInput
            type="tel"
            name={isB2B ? 'phoneNumber' : 'phone'}
            label={getInputPlaceholder(cosmicData, 'phone_number')}
            placeholder={cosmicData.metadata.input_fields.phone_number_format}
          />
        </Box>
        {'license_plate' in cosmicData.metadata.input_fields && (
          <Box>
            <FormikInput
              name={'licensePlate'}
              label={getInputPlaceholder(cosmicData, 'license_plate')}
            />
          </Box>
        )}
        {'fleet_size' in cosmicData.metadata.input_fields && (
          <Box>
            <FormikInput
              name="fleetSize"
              label={getInputPlaceholder(cosmicData, 'fleet_size')}
              type="number"
            />
          </Box>
        )}
        {'message' in cosmicData.metadata.input_fields && isB2B && (
          <div className={classes.message}>
            <TextArea
              name="message"
              placeholder={getInputPlaceholder(cosmicData, 'message')}
            />
          </div>
        )}
        {i18n.locale === 'de-DE' ? (
          <Box
            className={cn([
              classes.fullWidth,
              classes.gdpr_container,
              '[&_a]:link-16 [&_a:after]:!hidden [&_a:before]:!hidden',
            ])}
          >
            <FormikCheckbox name="gdpr" label={gdprCheckbox} />
            <div className="[&_p]:body-14-regular ml-6 [&_p]:pt-1">
              <Button variant="action" size="md" onClick={handleGDPRToggle}>
                {i18n.formatMessage({
                  id: `general.${
                    showGdprDetails ? 'lessInformation' : 'moreInformation'
                  }`,
                })}
              </Button>
              {showGdprDetails && (
                <>
                  <br />
                  <br />
                  <div className="[&_p]:body-14-regular [&_p]:pt-1">
                    {parseToHtml(gdprDetails)}
                  </div>
                </>
              )}
            </div>
          </Box>
        ) : (
          <Box
            className={cn([
              classes.fullWidth,
              classes.gdpr_container,
              '[&_a]:link-16 [&_a:after]:!hidden [&_a:before]:!hidden',
            ])}
          >
            <FormikCheckbox
              name="gdpr"
              label={parseToHtml(newsletterCheckboxText) as string}
            />
          </Box>
        )}
        <Box className={classes.button}>
          <Button
            onClick={() => {
              formik.handleSubmit();
            }}
            loading={loading}
          >
            {cosmicData.metadata.submit_button.button_label}
          </Button>
        </Box>
      </Box>
    </form>
  );
};
export default Form;
